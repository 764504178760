import config from "./index";

async function fetchDetails(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/details?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function fetchActivity(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/activity?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function fetchPayment(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/payments?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function createPayment(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/payments`, {
    method: "POST",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

async function deletePayment(payment) {
  const response = await fetch(`${config.apiUrl}/v1/project/payments`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(payment),
  });
  const data = await response.json();
  return data;
}

async function createPaymentComment(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/updates`, {
    method: "POST",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

async function fetchUpdates(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/updates?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function createUpdate(formData) {
  const response = await fetch(`${config.apiUrl}/v1/project/updates`, {
    method: "POST",
    headers: {
      "Authentication-Token": config.getToken(),
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

async function deleteUpdate(update) {
  const response = await fetch(`${config.apiUrl}/v1/project/updates`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(update),
  });
  const data = await response.json();
  return data;
}

async function updateProject(configData) {
  const response = await fetch(`${config.apiUrl}/v1/project/status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(configData),
  });
  const data = await response.json();
  return data;
}

async function fetchLOR(projectId) {
  const response = await fetch(
    `${config.apiUrl}/v1/project/letter?project_id=${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authentication-Token": config.getToken(),
      },
    },
  );
  const data = await response.json();
  return data;
}

async function updateLOR(project) {
  const response = await fetch(`${config.apiUrl}/v1/project/letter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authentication-Token": config.getToken(),
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
}

export default {
  fetchDetails,
  fetchActivity,
  fetchPayment,
  createPayment,
  deletePayment,
  createPaymentComment,
  fetchUpdates,
  createUpdate,
  deleteUpdate,
  updateProject,
  fetchLOR,
  updateLOR,
};
