import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePdfApp from "vue-pdf-app";
import i18n from "./dict";
import ElementUI from "element-ui";
import "./sass/element-variables.scss";
import locale from "element-ui/lib/locale/lang/en";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;
Vue.component("vue-pdf-app", VuePdfApp);
import "vue-pdf-app/dist/icons/main.css";

Vue.use(ElementUI, { locale });

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_KEY_MAP,
    libraries: "places",
  },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
