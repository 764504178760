<template>
  <v-container fluid class="my-5 mx-0 pa-0">
    <v-card style="width: 100%" elevation="0" min-height="50vh">
      <div class="pa-6">
        <div class="content payment-content py-2 px-6">
          <p class="ma-0" style="font-size: 22px">
            {{ $t("history.elements.details.payment.total") }}
          </p>
          <p
            style="color: #399652"
            class="font-weight-regular text-h5 text-lg-h4 ma-0"
          >
            {{ totalPayments }}
          </p>
        </div>
        <v-form
          class="principal-form"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="newPayment.amount"
                :rules="[
                  (v) =>
                    !!v ||
                    $t(
                      'history.elements.details.payment.validation.amountRequired',
                    ),
                ]"
                :label="$t('history.elements.details.payment.amount')"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="newPayment.description"
                :rules="[
                  (v) =>
                    !!v ||
                    $t(
                      'history.elements.details.payment.validation.descriptionRequired',
                    ),
                ]"
                :label="$t('history.elements.details.payment.description')"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="newPayment.content"
                :rules="[
                  (v) =>
                    !!v ||
                    $t(
                      'history.elements.details.payment.validation.detailRequired',
                    ),
                ]"
                :label="$t('history.elements.details.payment.detail')"
                outlined
                dense
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-file-input
                show-size
                v-model="newPayment.files"
                :rules="[
                  (v) =>
                    !v ||
                    v.length <= numberOfFiles ||
                    $t('history.elements.details.payment.validation.maxFiles'),
                  (v) =>
                    !v ||
                    v.every((file) => file.size <= weightOfFiles) ||
                    $t(
                      'history.elements.details.payment.validation.maxFileSize',
                    ),
                ]"
                multiple
                :label="$t('history.elements.details.payment.upload')"
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-btn
            class="edit-button mt-6"
            block
            @click="submitForm"
            :disabled="!valid"
          >
            {{ $t("history.elements.details.payment.newPayment") }}
          </v-btn>
        </v-form>
        <v-alert class="mt-3" v-if="error" type="error" dense text>
          {{ $t("history.elements.details.payment.error") }}
        </v-alert>
      </div>

      <v-divider></v-divider>

      <div class="pa-6" v-for="(payment, index) in payments" :key="index">
        <div class="content" style="position: relative">
          <v-btn
            icon
            class="context-menu-btn"
            @click.stop="showContextMenuPayment($event, payment, index)"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <div class="px-5 py-6">
            <div class="pays-header">
              <v-img
                max-height="50"
                max-width="60"
                src="@/assets/projectfullview/dolar.svg"
              ></v-img>
              <div>
                <p style="color: #399652" class="font-weight-regular ma-0">
                  $ {{ payment.amount }}
                </p>
                <p class="ma-0 text-body-2">{{ payment.description }}</p>
              </div>
            </div>
            <div class="d-flex justify-space-between align-center mt-3">
              <p style="text-decoration: underline" class="text-body-2">
                {{ payment.author.name }}
              </p>
              <p class="text-body-2">{{ formatDate(payment.date) }}</p>
            </div>
            <div
              class="message font-weight-regular text-body-2 pa-3"
              style="color: #433c3c"
            >
              {{ payment.content }}
            </div>
            <div class="pa-1 text-body-2">
              <span class="d-block mb-2 ml-1"
                >{{ $t("history.elements.details.payment.attachments") }}:</span
              >
              <v-chip
                class="mr-2 mb-2"
                v-for="(file, index) in payment.files"
                :key="index"
                color="#333"
                @click="openPdf(file.path)"
                style="color: white !important"
              >
                {{ truncateFileName(file.name) }}
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center px-5 py-2">
            <v-btn
              text
              color="#A09D9D"
              style="text-transform: none"
              @click="toggleReply(payment.payment_id)"
            >
              <v-icon left>mdi-reply</v-icon>
              {{ $t("history.elements.details.payment.reply") }}
            </v-btn>
          </div>
          <div v-if="showReplyId === payment.payment_id" class="px-5 py-2">
            <v-form
              :ref="`replyForm-${payment.payment_id}`"
              v-model="newReplies[payment.payment_id].valid"
              lazy-validation
            >
              <v-textarea
                v-model="newReplies[payment.payment_id].content"
                :rules="[
                  (v) =>
                    !!v ||
                    $t(
                      'history.elements.details.payment.validation.replyRequired',
                    ),
                ]"
                :label="$t('history.elements.details.payment.replyDetail')"
                outlined
                dense
              ></v-textarea>

              <v-file-input
                show-size
                v-model="newReplies[payment.payment_id].files"
                :rules="[
                  (v) =>
                    !v ||
                    v.length <= numberOfFiles ||
                    $t('history.elements.details.payment.validation.maxFiles'),
                  (v) =>
                    !v ||
                    v.every((file) => file.size <= weightOfFiles) ||
                    $t(
                      'history.elements.details.payment.validation.maxFileSize',
                    ),
                ]"
                multiple
                :label="$t('history.elements.details.payment.upload')"
                dense
              ></v-file-input>

              <v-btn
                :disabled="!newReplies[payment.payment_id].valid"
                text
                color="#A09D9D"
                class="mt-2"
                @click="sendReply"
                >{{ $t("history.elements.details.payment.sendReply") }}</v-btn
              >
            </v-form>
            <v-alert
              class="mt-3"
              v-if="newReplies[payment.payment_id].error"
              type="error"
              dense
              text
            >
              {{ $t("history.elements.details.payment.replyError") }}
            </v-alert>
          </div>
        </div>
        <div
          class="content comment px-6 py-3"
          style="position: relative"
          v-for="(comment, indexComment) in payment.comments"
          :key="indexComment"
        >
          <v-btn
            icon
            class="context-menu-btn"
            @click.stop="showContextMenuComment($event, comment, indexComment)"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <div class="d-flex justify-space-between align-center mt-6">
            <p style="text-decoration: underline" class="text-body-2">
              {{ comment.author.name }}
            </p>
            <p class="text-body-2">{{ formatDate(comment.date) }}</p>
          </div>
          <div
            class="message font-weight-regular text-body-2 pa-3"
            style="color: #433c3c"
          >
            {{ comment.content }}
          </div>
          <div class="pa-1 text-body-2">
            <span class="d-block mb-2 ml-1"
              >{{ $t("history.elements.details.payment.attachments") }}:</span
            >
            <v-chip
              class="mr-2 mb-2"
              v-for="(file, indexCommentFiles) in comment.files"
              :key="indexCommentFiles"
              color="#333"
              @click="openPdf(file.path)"
              style="color: white !important"
            >
              {{ truncateFileName(file.name) }}
            </v-chip>
          </div>
        </div>
      </div>
    </v-card>

    <!-- context menu payment -->
    <v-menu
      v-model="contextMenuVisiblePayment"
      :position-x="contextMenuPayment.x"
      :position-y="contextMenuPayment.y"
      absolute
      transition="scale-transition"
    >
      <v-list>
        <v-list-item @click="openPaymentConfirm">
          <v-list-item-title>{{
            $t("history.elements.details.payment.deletePayment")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- end context menu payment -->

    <!-- context menu comment -->
    <v-menu
      v-model="contextMenuVisibleComment"
      :position-x="contextMenuComment.x"
      :position-y="contextMenuComment.y"
      absolute
      transition="scale-transition"
    >
      <v-list>
        <v-list-item @click="openCommentConfirm">
          <v-list-item-title>{{
            $t("history.elements.details.payment.deleteComment")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- end context menu comment -->
  </v-container>
</template>

<script>
import moment from "moment";
import fullProject from "@/api/fullProject";
import { mapMutations } from "vuex";
export default {
  name: "Payment",
  data() {
    return {
      payments: [],
      valid: true,
      error: false,
      newPayment: {
        amount: "",
        description: "",
        content: "",
        files: [],
      },
      showReplyId: null,
      validReply: true,
      errorReply: false,
      newReplies: {},
      numberOfFiles: parseInt(process.env.VUE_APP_NUMBER_OF_FILES),
      weightOfFiles:
        parseInt(process.env.VUE_APP_WEIGHT_OF_FILES) * 1024 * 1024,
      contextMenuPayment: { x: 0, y: 0 },
      contextMenuVisiblePayment: false,
      selectedPayment: null,
      contextMenuComment: {
        x: 0,
        y: 0,
      },
      contextMenuVisibleComment: false,
      selectedComment: null,
    };
  },
  methods: {
    ...mapMutations(["setModalDocuments"]),
    openPdf(path) {
      this.setModalDocuments(path);
    },
    truncateFileName(name) {
      const extension = name.split(".").pop();
      const baseName = name.replace(`.${extension}`, "");
      if (baseName.length > 10) {
        return `${baseName.substring(0, 10)}... .${extension}`;
      }
      return name;
    },
    formatDate(dateString) {
      const date = moment(dateString, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
      const formattedDate = date.format("MM / DD / YYYY");
      return formattedDate;
    },
    toggleReply(payment_id) {
      if (this.showReplyId !== payment_id) {
        this.showReplyId = payment_id;
        if (!this.newReplies[payment_id]) {
          this.$set(this.newReplies, payment_id, {
            content: "",
            files: [],
            valid: true,
            error: false,
          });
        }
      } else {
        this.showReplyId = null;
      }
    },
    showContextMenuPayment(event, payment, index) {
      this.contextMenuPayment.x = event.clientX;
      this.contextMenuPayment.y = event.clientY;
      this.contextMenuVisiblePayment = true;
      this.selectedPayment = { payment, index };
    },
    showContextMenuComment(event, comment, index) {
      this.contextMenuComment.x = event.clientX;
      this.contextMenuComment.y = event.clientY;
      this.contextMenuVisibleComment = true;
      this.selectedComment = { comment, index };
    },
    async openPaymentConfirm() {
      this.contextMenuVisiblePayment = false;
      this.$confirm(
        this.$t("history.elements.details.payment.deletePaymentConfirmation"),
        "",
        {
          confirmButtonText: this.$t(
            "history.elements.details.payment.confirm",
          ),
          cancelButtonText: this.$t("history.elements.details.payment.cancel"),
          type: "warning",
        },
      )
        .then(async () => {
          try {
            const paymentId = this.selectedPayment.payment.payment_id;
            const projectId = this.$route.params.id;

            const data = {
              project_id: projectId,
              payment_id: paymentId,
            };

            const response = await fullProject.deletePayment(data);
            if (response.code === 200) {
              this.payments = response.data.payments;

              this.$notify({
                title: this.$t("notification.success"),
                message: this.$t("notification.successMessage"),
                type: "success",
              });
            } else {
              this.$notify({
                title: this.$t("notification.error"),
                message: this.$t("notification.errorMessage"),
                type: "error",
              });
            }
          } catch (error) {
            console.error(error);
            this.$notify({
              title: this.$t("notification.error"),
              message: this.$t("notification.errorMessage"),
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: this.$t("notification.warning"),
            message: this.$t("notification.warningMessage"),
            type: "warning",
          });
        });
    },
    async openCommentConfirm() {
      this.contextMenuVisibleComment = false;
      this.$confirm(
        this.$t("history.elements.details.payment.deleteCommentConfirmation"),
        "",
        {
          confirmButtonText: this.$t(
            "history.elements.details.payment.confirm",
          ),
          cancelButtonText: this.$t("history.elements.details.payment.cancel"),
          type: "warning",
        },
      )
        .then(async () => {
          try {
            const commentId = this.selectedComment.comment.comment_id;
            const projectId = this.$route.params.id;

            const data = {
              project_id: projectId,
              comment_id: commentId,
            };

            const response = await fullProject.deleteUpdate(data);
            if (response.code === 200) {
              this.payments = response.data.payments;

              this.$notify({
                title: this.$t("notification.success"),
                message: this.$t("notification.successMessage"),
                type: "success",
              });
            } else {
              this.$notify({
                title: this.$t("notification.error"),
                message: this.$t("notification.errorMessage"),
                type: "error",
              });
            }
          } catch (error) {
            console.error(error);
            this.$notify({
              title: this.$t("notification.error"),
              message: this.$t("notification.errorMessage"),
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: this.$t("notification.warning"),
            message: this.$t("notification.warningMessage"),
            type: "warning",
          });
        });
    },
    async getPayments() {
      try {
        const response = await fullProject.fetchPayment(this.$route.params.id);
        if (response.code === 200) this.payments = response.data.payments;
      } catch (error) {
        console.log(error);
      }
    },
    async submitForm() {
      const formData = new FormData();
      this.newPayment.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("project_id", this.$route.params.id);
      formData.append("amount", this.newPayment.amount);
      formData.append("description", this.newPayment.description);
      formData.append("content", this.newPayment.content);

      if (this.$refs.form.validate()) {
        this.valid = false;
        this.error = false;

        try {
          const response = await fullProject.createPayment(formData);
          if (response.code == 200) {
            this.valid = true;
            this.$refs.form.reset();
            this.payments = response.data.payments;
          } else {
            this.valid = true;
            this.error = true;
          }
        } catch (error) {
          this.valid = true;
          this.error = true;
        }
      }
    },
    async sendReply() {
      const formData = new FormData();
      const paymentId = this.showReplyId;
      const replyData = this.newReplies[paymentId];
      const replyForm = this.$refs[`replyForm-${paymentId}`][0];

      replyData.files.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
      formData.append("project_id", this.$route.params.id);
      formData.append("payment_id", paymentId);
      formData.append("content", replyData.content);

      if (replyForm.validate()) {
        this.newReplies[paymentId].valid = false; // Antes de la operación asincrónica
        this.newReplies[paymentId].error = false;

        try {
          const response = await fullProject.createPaymentComment(formData);
          if (response.code === 200) {
            replyForm.reset();
            this.payments = response.data.payments;
          } else {
            this.newReplies[paymentId].valid = true;
            this.newReplies[paymentId].error = true;
          }
        } catch (error) {
          console.error(error);
          this.newReplies[paymentId].valid = true;
          this.newReplies[paymentId].error = true;
        }
      }
    },
  },
  computed: {
    totalPayments() {
      const total = this.payments.reduce(
        (acc, payment) => acc + payment.amount,
        0,
      );
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  async created() {
    await this.getPayments();
  },
};
</script>

<style scoped>
.content {
  border: 1px solid #b5c0cc;
  border-radius: 12px;
  margin-bottom: 1rem;
}

.comment {
  width: 85%;
  margin-left: 15%;
}

.principal-form {
  border: 1px solid #b5c0cc;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: 2rem 1.5rem;
}

.payment-content {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pays-header {
  display: flex;
  justify-content: left;
  align-items: center;
}

.message {
  min-height: 90px;
  max-height: 270px;
  border-radius: 12px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  overflow: hidden;
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  font-family: sans-serif !important;
  height: 35px !important;
}

.col {
  padding: 0 0.5rem;
}

.context-menu-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
