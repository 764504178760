import Vue from "vue";
import Vuex from "vuex";
import api from "@/api/user";

Vue.use(Vuex);

const initialState = {
  user: {},
  newProject: {
    customerUpload: false,
    scopeUpload: false,
    externalFiles: false,
    internalFiles: false,
    documentsUpload: false,
  },
  modalDocuments: {
    dialogPdfViewer: false,
    documentUrl: "",
    isPdf: false,
    isOfficeDocument: false,
  },
  downloadFiles: {
    downloading: false,
    folderName: "",
    numberOfFiles: 0,
    filesDownloaded: 0,
  },
  userLoaded: false,
  loading: false,
};

export default new Vuex.Store({
  state: { ...initialState },
  getters: {
    getUser: (state) => state.user,
    isUserLoaded: (state) => state.userLoaded,
    isLoading: (state) => state.loading,
    getNewProject: (state) => state.newProject,
    getModalDocuments: (state) => state.modalDocuments,
    getDownloadFiles: (state) => state.downloadFiles,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.userLoaded = true;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCustomerUpload(state, value) {
      state.newProject.customerUpload = value;
    },
    setScopeUpload(state, value) {
      state.newProject.scopeUpload = value;
    },
    setExternalFiles(state, value) {
      state.newProject.externalFiles = value;
    },
    setInternalFiles(state, value) {
      state.newProject.internalFiles = value;
    },
    setDocumentsUpload(state, value) {
      state.newProject.documentsUpload = value;
    },
    resetNewProject(state) {
      state.newProject = {
        customerUpload: false,
        scopeUpload: false,
        externalFiles: false,
        internalFiles: false,
        documentsUpload: false,
      };
    },
    resetState(state) {
      localStorage.removeItem("authToken");
      Object.assign(state, initialState);
    },
    setModalDocuments(state, path) {
      const lowerCasePath = path.toLowerCase();
      state.modalDocuments.documentUrl = `${process.env.VUE_APP_BUCKET_URL}${path}`;
      state.modalDocuments.isPdf = lowerCasePath.endsWith(".pdf");
      state.modalDocuments.isOfficeDocument =
        lowerCasePath.endsWith(".docx") || lowerCasePath.endsWith(".doc");
      state.modalDocuments.dialogPdfViewer = true;
    },
    setInitDownloadFiles(state, downloadInfo) {
      state.downloadFiles.numberOfFiles = downloadInfo.numberOfFiles;
      state.downloadFiles.folderName = downloadInfo.folderName;
      state.downloadFiles.downloading = true;
    },
    incrementFilesDownloaded(state) {
      state.downloadFiles.filesDownloaded++;
    },
    resetDownloadFiles(state) {
      state.downloadFiles = {
        downloading: false,
        folderName: "",
        numberOfFiles: 0,
        filesDownloaded: 0,
      };
    },
  },
  actions: {
    async authenticateAndLoadUser({ commit }, isLoggingIn) {
      if (isLoggingIn) {
        commit("setLoading", true);
      }
      try {
        const response = await api.fetchUser();
        if (response.code == 200) {
          commit("setUser", response.data.user);
        } else {
          throw new Error("Unable to load user");
        }
      } catch (error) {
        console.error("Error loading user: ", error);
        commit("resetState");
        throw error;
      } finally {
        if (isLoggingIn) {
          commit("setLoading", false);
        }
      }
    },
  },
  modules: {
    // Here you can add other modules if necessary
  },
});
