<template>
  <v-container
    fluid
    class="d-flex justify-center align-center"
    style="height: 100%"
  >
    <v-card
      class="d-flex flex-column justify-center align-center"
      min-height="98%"
      min-width="95%"
      elevation="0"
    >
      <header class="d-flex flex-column mt-5">
        <p class="mb-7 text-center" style="font-size: 20px">
          {{ $t("projects.newProjectBasicInformation.newSubmission") }}
          <span class="d-block">{{
            $t("projects.newProjectBasicInformation.letsGetStarted")
          }}</span>
        </p>
        <p class="mb-7">
          {{ $t("projects.newProjectBasicInformation.enterUniqueName") }}
        </p>
      </header>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$t('projects.newProjectBasicInformation.insuredName')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <GmapAutocomplete
              @place_changed="setPlace"
              ref="gmapAutocomplete"
              class="autocomplete"
            />
          </v-col>
          <v-col cols="12">
            <div class="map">
              <gmap-map
                :center="center"
                :zoom="14"
                :options="{ streetViewControl: false, mapTypeControl: false }"
              >
                <GmapMarker
                  :position="marker.position"
                  :clickable="true"
                  :draggable="true"
                  @dragend="onMarkerDragEnd"
                />
              </gmap-map>
            </div>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address"
              :rules="addressRules"
              :label="$t('projects.newProjectBasicInformation.address')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="address2"
              :label="$t('projects.newProjectBasicInformation.addressLine2')"
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="city"
              :rules="cityRules"
              :label="$t('projects.newProjectBasicInformation.city')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="state"
              :rules="stateRules"
              :label="$t('projects.newProjectBasicInformation.state')"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="zip"
              :rules="zipRules"
              :label="$t('projects.newProjectBasicInformation.zipCode')"
              outlined
              type="number"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            v-if="
              ['superuser', 'developer'].includes($store.getters.getUser.role)
            "
          >
            <v-autocomplete
              :items="companys"
              item-text="name"
              item-value="id"
              :label="$t('projects.newProjectBasicInformation.company')"
              v-model="companyId"
              outlined
              :rules="companyIdRules"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <div class="d-flex justify-center mb-5" style="width: 100%">
          <v-btn
            @click="createProject"
            :disabled="!valid"
            class="edit-button mt-10"
          >
            {{
              $t("projects.newProjectBasicInformation.confirmProjectLocation")
            }}
          </v-btn>
        </div>
      </v-form>
      <v-alert v-if="error" type="error" dense text>
        {{ $t("projects.newProjectBasicInformation.errorOccurred") }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import project from "@/api/project";
import companies from "@/api/companies";
import { gmapApi } from "vue2-google-maps";
export default {
  name: "NewProjectBasicInformation",
  data() {
    return {
      marker: {
        position: {
          lat: 1.38,
          lng: 103.8,
        },
      },
      center: { lat: 1.38, lng: 103.8 },
      valid: true,
      error: false,
      name: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      companyId: "",
      companys: [],
      nameRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.nameRequired"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("projects.newProjectBasicInformation.nameMinLength"),
      ],
      addressRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.addressRequired"),
        (v) =>
          (v && v.length >= 5) ||
          this.$t("projects.newProjectBasicInformation.addressMinLength"),
      ],
      cityRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.cityRequired"),
        (v) =>
          (v && v.length >= 4) ||
          this.$t("projects.newProjectBasicInformation.cityMinLength"),
      ],
      stateRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.stateRequired"),
        (v) =>
          (v && v.length >= 2) ||
          this.$t("projects.newProjectBasicInformation.stateMinLength"),
      ],
      zipRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.zipRequired"),
        (v) =>
          /^\d+$/.test(v) ||
          this.$t("projects.newProjectBasicInformation.zipMustBeNumber"),
      ],
      companyIdRules: [
        (v) =>
          !!v || this.$t("projects.newProjectBasicInformation.companyRequired"),
      ],
    };
  },
  methods: {
    async createProject() {
      if (this.$refs.form.validate()) {
        this.valid = false;
        this.error = false;
        try {
          const data = {
            name: this.name,
            address: this.address,
            address_line: this.address2,
            city: this.city,
            state: this.state,
            zip_code: this.zip,
          };

          if (
            ["superuser", "developer"].includes(
              this.$store.getters.getUser.role,
            )
          ) {
            data.company_id = this.companyId;
          }

          const response = await project.createProject(data);
          if (response.code == 200) {
            this.valid = true;
            this.$refs.form.reset();
            this.$emit("completed", response.data.project);
          } else {
            this.valid = true;
            this.error = true;
          }
        } catch (error) {
          this.valid = true;
          this.error = true;
        }
      }
    },
    async getCompanies() {
      try {
        const response = await companies.fetchCompanies();
        if (response.code == 200) {
          this.companys = response.data.companies;
        } else {
          this.companys = [];
        }
      } catch (error) {
        console.error(error);
        this.companys = [];
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    setPlace(place) {
      console.log(place);
      const location = place.geometry.location;

      // Actualiza el centro y la posición del marcador
      this.center = {
        lat: location.lat(),
        lng: location.lng(),
      };
      this.marker.position = {
        lat: location.lat(),
        lng: location.lng(),
      };

      // Establece la dirección completa
      this.address = place.formatted_address;

      // Extrae los componentes relevantes de la dirección
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          this.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          this.state = component.short_name;
        }
        if (component.types.includes("postal_code")) {
          this.zip = component.long_name;
        }
      });
    },
    async onMarkerDragEnd(event) {
      if (!this.google) {
        console.error("Google Maps API no está disponible aún.");
        return;
      }

      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      this.marker.position = { lat, lng };
      this.center = { lat, lng };

      // Usando la API de geocodificación de Google
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];
          this.$refs.gmapAutocomplete.$refs.input.value =
            place.formatted_address;
          this.address = place.formatted_address;

          // Extraer detalles específicos de la dirección
          place.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              this.city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              this.state = component.short_name;
            }
            if (component.types.includes("postal_code")) {
              this.zip = component.long_name;
            }
          });
        } else {
          console.error("No se pudo obtener la dirección:", status);
        }
      });
    },
  },
  computed: {
    google: gmapApi,
  },

  activated() {
    this.getCompanies();
    this.geolocate();
  },
};
</script>

<style scoped>
header,
form {
  width: 90%;
}

@media (min-width: 768px) {
  header,
  form {
    width: 40%;
  }
}

.edit-button {
  background-color: #2b58e2 !important;
  color: white !important;
  text-transform: none;
  width: 90% !important;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.map {
  border-radius: 15px;
  margin-bottom: 30px;
  aspect-ratio: 4/3;
}

.vue-map-container {
  height: 100%;
  width: auto;
}

.autocomplete {
  width: 100%;
  font-size: 16px;
  padding: 12px;
  border: 1.5px solid #ccc;
  border-radius: 11px;
  height: 56px;
  margin-bottom: 24px;
}
</style>
